#background {
  background-color:#6DB39C;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -100;
}
.body {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

