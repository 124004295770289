.h1Rules {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: .5rem;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
}

.bodyRules {
  margin-left: 3rem;
  margin-right: 3rem;
  width: 205px;
  opacity: 0.8;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

.h1Start {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: .5rem;
  width: 253px;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
}

.bodyStart {
  margin-left: 3rem;
  margin-right: 3rem;
  width: 214px;
  opacity: 0.8;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
}

@font-face {
  font-family: 'WT Gothic';
  src: url('../fonts/WTGothic-Regular.otf') format('opentype');
}
