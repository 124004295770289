.content{
  height: 100%;
}

.buttonImg {
  height: 13px;
  width: 16px;
}

.buttonNext {
  border-radius: 40px;
  height: 44px;
  width: 44px;
  padding: 0;
  border: none;
  background-color: #ffe400;
  float: right;
  margin-right: 2rem;
  margin-top: 0rem;  
}

.skip {
  color: black;
  float: right;
  margin-right: 1rem;
  margin-top: .5rem;  
}