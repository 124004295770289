.hr {
  height: 1px;
  width: 100%;
  background-color: #000;
  border: none;
}

.heading {
  padding: 0 1rem;
}

.underlinedText {
  text-decoration: underline;
}

.boldText {
  font-weight: 700;
}

.profileImg {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 50%;
}

.leaderboardEntry {
  display: flex;
  height: 3.5rem;
  padding: 0.25rem 0.5rem;
  align-items: center;
  justify-content: space-between;
}

.avatar {
  display: flex;
  align-items: center;
}

.username {
  font-family: 'WT Gothic Semi-Bold';
  padding-left: .5rem;
}

@font-face {
  font-family: 'WT Gothic Semi-Bold';
  src: url('../fonts/WTGothic-Semi-Bold.otf') format('opentype');
}

.globalContainer {
  height: calc(100vh - 5rem);
}

.headingContainer {
  padding: 2rem;
  height: 5rem;
}

.ghostContainer {
  height: calc(100vh - 14rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ghostContainer h2 {
  text-align: center;
  padding: 2rem;
}

.topContainer {
  background-color: #FFE500;
  height: 17rem;
  padding: .5rem;
}

.topContainerRank1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 7rem;
  width: 3rem;

}

.bottomContainer {
  height: calc(100% - 18rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  padding: 0 2rem;
}

.profileImgTop1 {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding-top: .5rem;
}

.leaderboardEntryTop1 {
  display: flex;
  flex-direction: column;
  height: 9.5rem;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  width: 7.5rem;
  border-radius: 5%;
}

.usernameTop1 {
  font-family: 'WT Gothic Semi-Bold';
}

.topContainerRank2 {
  justify-content: space-evenly;
  display: flex;
  align-items: end;
  padding-top: 1rem;
  height: 10rem;
  width: 100%;

}

.profileImgTop2 {
  padding-top: .5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.leaderboardEntryTop2 {
  display: flex;
  flex-direction: column;
  height: 8.5rem;
  justify-content: center;
  background-color: #fff;
  align-items: center;
  width: 6.5rem;
  border-radius: 5%;
}

.usernameTop2 {
  font-family: 'WT Gothic Semi-Bold';
}

.pointsIcon {
  height: 9px;
  padding-left: .5rem;
}

.pointsContainer {
  display: flex;

  align-items: center;
}

.points {
  font-size: 1rem;
  font-family: 'WT Gothic Semi-Bold';
}

.placement {
  margin-right: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1rem;
  font-family: 'WT Gothic Semi-Bold';
}

.placementOWN {
  background-color: #6DB39C;
  margin-right: 1rem;
  padding-left: .75rem;
  padding-right: .75rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: 1rem;
  font-family: 'WT Gothic Semi-Bold';
  border-radius: 5px;
}

.top1Icon {
  position: absolute;
  margin-left: 20% !important;
  margin-bottom: 29% !important;
  background-color: #FFE330;
  border-radius: 50%;
  font-size: 9pt;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.top2Icon {
  position: absolute;
  margin-right: 45% !important;
  margin-bottom: 27% !important;
  background-color: #E0EBEC;
  border-radius: 50%;
  font-size: 8pt;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}

.top3Icon {
  position: absolute;
  margin-left: 80% !important;
  margin-bottom: 27% !important;
  background-color: #EAC5B2;
  border-radius: 50%;
  font-size: 8pt;
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
}