.ReactSwipeButton {
  margin-top: 1rem;
  width: 164px;
  height: 36px;
  position: relative;
  float: right;
  margin-right: 3rem;
  background-color: #FFE300;
}
.ReactSwipeButton,
.ReactSwipeButton * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    not supported by any browser */
}
.rsbContainer {
  float: left;
  width: 165px;
  height: 100%;
  background: #ffffff;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
}

.rsbcSlider {
  float: left;
  width: 164px;
  position: absolute;
  height: 36px;
  top: 0;
  left: 36px;
  margin-left: -100%;
  background: #ffffff;
  border-radius: 25px;
  z-index: 100;
  cursor: pointer;
}

@font-face {
  font-family: 'WT-Gothic';
  src: url('../fonts/WTGothic-Regular.otf') format('opentype');
}

.rsbcSliderText {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  line-height: 50px;
  text-align: center;
  font-family: "WT-Gothic";
  letter-spacing: 2px;
  color: #ffffff;
  font-size: 13px;
}
.rsbcSliderArrow {
  float: left;
  position: absolute;
  height: 8px;
  width: 12px;
  right: 11px;
  border-left-color: transparent;
  border-bottom-color: transparent;
  transform-origin: center;
  z-index: 10;
  padding-top: 5px;
}
.rsbcSliderCircle {
  position: absolute;
  right: 0;
  background: #b0bbbf;
  top: 1px;
  height: 34px;
  width: 34px;
  border-radius: 100%;
}
.rsbcText {
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-family: "WT-Gothic";
  letter-spacing: 2px;
  font-size: 13px;
  color: #202221;
}
