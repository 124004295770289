#topChallengeSelected {
  height: 100%;
  width: 100%;
  background-color: #FFE500;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#TopBox {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#TopBoxText {
  width: 65%;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 17px;
}

#Hourglass {
  height: 100px;

}