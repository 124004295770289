@font-face {
  font-family: 'WT Gothic';
  src: url('../fonts/WTGothic-Semi-Bold.otf') format('opentype');
}

/* .bgTop {
  ;
  height: 60vh;
}

.bgBot {
  background-color: #FFFFFF;
  height: 40vh;
}
.box{
  position: fixed;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}


.card {
  height: 60vh;
  width: 80%;
  background-color: #fff;
  box-shadow: 5px;
} */


#BtnBox {
  display: flex;
  width: 100%;
  justify-content: center;
}

#acptBtn {
  background-color: #FFE500;
  border: none;
  padding: 0;
  height: 36px;
  width: 80%;
  border-radius: 25px;
  font-family: 'WT Gothic';
}

#acptBtnB {
  color: #fff;
  background-color: #202221;
  border: none;
  padding: 0;
  height: 36px;
  width: 80%;
  border-radius: 25px;
  font-family: 'WT Gothic';
}

#acptBtn:focus {
  outline: none;


}

/* #acptBtn:active {
  background-color: #BCA700;
} */

.rules {
  display: none;
  position: absolute;
  position: fixed;
  z-index: 1000000;
  bottom: 0;

}

.btn {
  border: none;
  background-color: #fff;
  height: 20px;
  font-size: 15px;
  font-family: 'WT Gothic';
  margin-left: 3rem;
  margin-bottom: 2rem;
  position: absolute;
  bottom: 0;
  text-decoration: underline;

}

#topChallengeSelected {
  height: 100%;
  width: 100%;
  background-color: #FFE500;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#Hourglass {
  height: 60px;

}

#TopBox {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

#TopBoxText {
  width: 65%;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

#TopBoxTextB {
  width: 50%;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 17px;
}

#something {
  z-index: 10;
}