.navContainer {
  background-color: #6DB39C;
  height: 5rem;
  display: flex;
  justify-content: space-between;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  align-items: center;
}

.navigation {
  background-color:
    #FFE500;
  height: 93vh;
  width: 100%;
  display: none;
  position: absolute;
  z-index: 1000;
  position: relative;
}

#root {
  overflow: hidden;
 }
 

.profileImg {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
  border-radius: 50%;
}

.user {
  display: flex;
}

.textBox {
  padding-left: 1rem;
  font-family: 'WT Gothic Semi-Bold';
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@font-face {
  font-family: 'WT Gothic Semi-Bold';
  src: url('../fonts/WTGothic-Semi-Bold.otf') format('opentype');
}

.userName {
  font-size: 14px;
}

.score {
  font-size: 10px;
}

.tos {
  display: flex;
  align-items: flex-end;
  margin-left: 1.5rem;
}

.tos li {
  list-style: none;
  padding-top: 3rem;
  margin-left: 0.5rem;

}

.tos li a {
  font-family: "WT Gothic";
  color: #1A1A1A;
  font-weight: 500;
}

.burgerMenu {
  width: 40px !important;
  height: auto;
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
}

.links {
  display: flex;
  flex-direction: column;
  padding-top: 3rem;
  height: 80%;
  margin-left: 1.5rem;
}

.links li {
  list-style: none;
  color:
  #6DB39C;
  font-family: "WT Gothic";
  font-size: 35px;
  letter-spacing: 0;
  line-height: 69px;
}

.links li a {
  color:
  #6DB39C;
  text-decoration: none;
}