#background {
  background-image: url("../Onboarding_Assets/Onbarding_Intro_Animation_v1.gif");
  background-size: contain;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -100;
}

#acptBtnB {
  color: #fff;
  background-color: #202221;
  border: none;
  padding: 0;
margin-top: 1rem;
  height: 36px;
  width: 80%;
  border-radius: 25px;
  font-family: 'WT Gothic';
  z-index: 9;
}

#acptBtnB :active {
  color: #fff;
  background-color: #fff;
}

#BtnBox {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: 2rem;
}

#cardPlacement{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


#BtnBoxB {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}

.profileimg {
  display: inline-block;
  width: 45%;
  height: 90%;
  overflow: hidden;
  border-radius: 50%;
}

.profileimgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}