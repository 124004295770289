.content {
  background-color: #FFE300;
  height: 100%;
}
.nicknameinput {
  margin-top: 25rem;
  margin-left: 3rem;
  background-color: #ffe300;
  height: 36px;
  width: 75%;
  border-top: 1px black;
  border-right: 1px black;
  border-left: 1px black;
  font-size: 22px;
  color: #000;
  font-family: 'WT Gothic';
}

/* input {
  margin-top: 30rem;
  margin-left: 3rem;
  background-color: #ffe300;
  height: 36px;
  width: 75%;
  border-top: 1px black;
  border-right: 1px black;
  border-left: 1px black;
  font-size: 22px;
  color: #000;
  font-family: 'WT Gothic';
} */

input:focus {
  outline: none;
  margin-top: 17rem;
}

#hint {
  visibility: hidden;
  position: fixed;
  margin-top: 12rem;
  margin-left: 3rem;
  font-size: 14px;
  font-family: 'WT Gothic';
}

#inputTitle{
  visibility: hidden;
  position: fixed;
  margin-top: 16rem;
  margin-left: 3rem;
  font-size: 10px;
  font-family: 'WT Gothic';
}