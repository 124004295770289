#background{
  height: 100vh;
  background-color: #ffe500;
  overflow: hidden;
}

#title{
  font-family: 'WT-Gothic Semi-Bold';
  color: #000000;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26px;
  margin: 2rem;
}

#text{
  font-family: 'WT-Gothic';
  opacity: 0.8;
  color: #000000;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin: 2rem;
}

#Image{
  width: 90%;
  margin-left: 3rem;
}

@font-face {
  font-family: 'WT-Gothic';
  src: url('../fonts/WTGothic-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'WT-Gothic Semi-Bold';
  src: url('../fonts/WTGothic-Semi-Bold.otf') format('opentype');
}