.slider-wrapper {
  padding-bottom: 5px;
}

/* .ui {
  height: 500px;
} */

.description {
  height: 160px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#qrCanvas {
  height: 90vh !important;
  width: 100% !important;
}

.burger_hamburger--vortex__26OQz {
  margin-top: 0 !important;
}