div section section {
  height: 44vh;
}

div section section div {
border-width: 270px 90px 270px 90px !important;
border-style: solid !important;
border-color: rgba(0, 0, 0, 0.2)  !important;
box-shadow: rgb(230 230 230 / 100%) 0px 0px 0px 5px inset !important;
}

.fade, .modal, .show {
  position: absolute;
  bottom: 70%;
  margin: 1rem;
  background-color: white;
  z-index: 2;
  border-radius: 10px;
  padding: 0.5rem;
  float: left;
}

.closeImg {
  width: 5%;
  float: right;
}

.helperIcon {
  width: 20%;
  float: right;
  padding-right: 1rem;
}

.popUptxt {
  width: 50%;
  padding-left: 1.5rem;
  font-family: "WT-Gothic";
}

.modal-body {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

@font-face {
  font-family: 'WT-Gothic';
  src: url('../fonts/WTGothic-Regular.otf') format('opentype');
}
