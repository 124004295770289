.body {
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}


#background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60%;
  background-color: #FFE300;
  z-index: -100;
}