.bgTop {
  height: 650px;
  background-color: #fff;
  border-radius: 25px;
  margin-left: 1rem;
  margin-right: 1rem;
}

.iconSize {
  width: 60px;
  padding-bottom: 1.5rem;
}

.text01 {
  height: 52px;
  width: 181px;
}

.chooseWise {
  height: 30px;
  width: 78px;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
}

.chooseAChallengeT {
  height: 32px;
  width: 181px;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.rules {
  display: flex;
}

.ruleTitle {
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 10px;
}

.ruleText {
  color: #202221;
  font-family: "WT Gothic";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}

.ruleContainer {
  height: 100%;
  margin-left: 3rem;
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}

.textBox {
  margin-left: 1rem;
}

@font-face {
  font-family: 'WT Gothic';
  src: url('../fonts/WTGothic-Regular.otf') format('opentype');
}

#BtnBox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 2rem;
}

#acptBtn {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 0;
  height: 36px;
  width: 80%;
  border-radius: 25px;
  font-family: 'WT Gothic';
}

#acptBtn:focus {
  outline: none;


}

.transparent {
  width: 100%;
  padding-top: 15rem;
  background-color: rgba(36, 36, 35, 0.4);
}