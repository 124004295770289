.bgTop {
  height: 527px;
  width: 100%;
  background-color: #ffe500;
}

.iconSize {
  width: 60px;
  padding-bottom: 1rem;
}

.text01 {
  height: 52px;
  width: 181px;
}

.chooseWise {
  height: 30px;
  width: 78px;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
}

.chooseAChallengeT {
  height: 32px;
  width: 181px;
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
}

.rules {
  display: flex;
}

.ruleTitle {
  color: #202221;
  font-family: "WT Gothic";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15px;
  margin-bottom: 10px;
}

.ruleText {
  color: #202221;
  font-family: "WT Gothic";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 16px;
}

.ruleContainer {
  height: 100%;
  margin-left: 3rem;
  margin-right: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

.textBox {
  margin-left: 1rem;
}

@font-face {
  font-family: 'WT Gothic';
  src: url('../fonts/WTGothic-Regular.otf') format('opentype');
}