.layer {

  position: absolute;
  top: 10vh;
  left: 0;
  width: 100%;
  height: 90vh;
  overflow: hidden;
  display: inline-block;
}

.scannerpointer {
  content: "";
  display: block;

  /* Scale */
  width: 50%;
  height: 0;
  padding-bottom: 50%;

  /* Position */
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  /* Border */
  border: solid 400px rgba(36, 36, 35, 0.4);

}

.scannerpointer::before {
  content: "";
  display: block;

  /* Scale */
  width: 100%;
  height: 50%;
  padding-bottom: 50%;

  /* Position */
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);

  /* Border */
  border: solid 5px rgba(255, 255, 255, 0.8);

}